.dashboard {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  font-family: "Inter";
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  .loading {
    display: none;

    font-weight: 500;
    margin-left: -64px;
    text-align: center;
  }

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 3px solid black;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: black transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .box {
    width: 600px;
    // height: 500px;
    background-color: #f5f5f5;
    // border: rgba(220, 220, 220, 0.8) 2px solid;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

    .user {
      float: right;
      // padding: 12px;
      width: 600px;
      border-bottom: 1px solid rgb(20, 20, 20, 0.1);
      height: 64px;

      p {
        font-weight: 500;
        font-size: 18px;
        float: right;
        line-height: 32px;
        padding: 0;
        margin-right: 12px;
      }

      img {
        border-radius: 50%;
        border: 1px solid rgb(40, 40, 40, 0.2);
        height: 36px;
        margin-top: 14px;
        margin-right: 22px;
        float: right;
      }

      // .logo {
      // 	border: 0px;
      // 	width: 32px;
      // 	height: 32px;
      // }
    }

    .billing,
    .membership,
    .support {
      width: 552px;
      margin-bottom: 18px;
      float: left;
      margin-left: 24px;
      margin-right: 24px;

      .content {
        float: left;
        margin-left: 32px;
        width: 552px;
      }
    }

    .renewal,
    .staffmember {
      // float: right;
      border: 1.5px solid rgb(80, 80, 80, 0.2);
      background-color: rgb(255, 255, 255);
      border-radius: 6px;
      padding: 12px;

      p {
        padding: 0px;
        margin: 0;
        font-size: 14px;
        font-weight: 300;
        opacity: 0.7;
        padding-bottom: 4px;
      }
    }

    .staffmember {
      float: left;
      margin-right: 12px;
      margin-top: 12px;
      width: 232px;
    }

    .license {
      float: right;
      border: 1.5px solid rgb(80, 80, 80, 0.2);
      background-color: rgb(255, 255, 255);
      border-radius: 6px;
      padding: 12px;
      margin-right: 28px;
      width: 224px;

      p {
        padding: 0px;
        margin: 0;
        font-size: 14px;
        font-weight: 300;
        opacity: 0.7;
        padding-bottom: 4px;
      }
    }

    input,
    select {
      width: 430px;
      border: 1.5px solid rgb(80, 80, 80, 0.2);
      border-radius: 6px;
      padding: 12px;
      margin-top: 12px;
      margin-left: 100px;
      box-sizing: border-box;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      color: black;
      background-color: white;
      width: 210px;
      float: left;
    }

    .card {
      float: left;
      width: 430px;
      margin-left: 100px;
      margin-top: 24px;
      padding: 12px;
      border: 1.5px solid rgb(80, 80, 80, 0.2);
      background-color: white;
      border-radius: 6px;
      box-sizing: border-box;
    }

    .bottom {
      float: left;
      border: 1px solid red;
      height: 32px;
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .tac {
      float: left;
      // margin-top: -82px;
      text-align: center;
      margin-top: 12px;
      margin-bottom: 12px;
      // line-height: 32px;
      margin-left: 12px;
      width: 100%;
      font-size: 10px;
      font-weight: 400;
      color: rgb(40, 40, 40, 0.4);
    }

    .join {
      // float: left;
      width: 100px;
      margin-right: 80px;
      margin-left: 260px;
      font-weight: 500;
      // margin-top: 45px;
      margin-bottom: 24px;
      height: 32px;
      border-radius: 4px;
      background-color: royalblue;
      border: 2px solid rgb(0, 0, 0, 0.1);
      color: white;
      transition: background-color 0.3s ease;
    }

    .join:hover {
      cursor: pointer;
      background-color: rgb(48, 85, 199);
    }

    .payment-details {
      // margin-top: 24px;
      float: left;
      border: 1.5px solid rgb(80, 80, 80, 0.2);
      background-color: rgb(255, 255, 255);
      width: 520px;
      height: 61px;
      margin-left: 32px;
      border-radius: 6px;
      // padding: 12px;

      p {
        float: left;
        margin-top: 20px;
        margin-left: 14px;
      }

      .provider {
        width: 32px;
        border-radius: 2px;
        margin-left: 18px;
        margin-top: 12px;
        padding: 2px;
        float: left;
        border: 1px solid rgba(209, 209, 209, 0.8);
      }

      .remove-payment {
        float: right;
        border: 1px solid rgb(255, 71, 71);
        color: rgb(177, 29, 29);
        width: 72px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        font-size: 12px;
        border-radius: 4px;
        margin-top: 16px;
        margin-right: 16px;
      }

      .remove-payment::before {
        top: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 1;
      }

      .remove-payment:hover::before {
        opacity: 0;
      }
    }

    .divider {
      border: 0.5px solid rgb(40, 40, 40, 0.5);
      width: 552px;
      margin-left: 24px;
      margin-top: 24px;
      margin-bottom: -24px;
      opacity: 0.4;
    }

    .subtitle {
      float: left;
      padding: 0px;
      font-weight: 500;
      font-size: 18px;
      letter-spacing: -0.1px;

      p {
        float: left;
        margin-left: 12px;
      }

      img {
        float: left;
        width: 20px;
        margin-top: 22px;
      }

      .envelope {
        margin-top: 21px;
      }

      .person {
        margin-top: 17px;
      }
    }

    .title {
      text-align: center;
      margin-top: 48px;
      font-weight: 600;
      font-size: 18px;
    }

    .subheader {
      margin-top: -8px;
      font-weight: 400;
      font-size: 12px;
      margin-left: 12px;
      margin-right: 12px;
    }

    .waitlist-management {
      margin-top: 24px;
      padding-top: 36px;
      text-align: center;
    }

    .leave-waitlist,
    .sign-out {
      // border: 1px solid red;
      width: 64px;
      height: 24px;
      float: left;
      font-size: 12px;
      font-weight: 400;
      text-decoration: underline;
      color: rgb(177, 29, 29);
      text-align: center;
      margin: auto;
      margin-left: 268px;
      margin-top: -12px;
      padding-bottom: 28px;

      p:hover {
        cursor: pointer;
        color: rgb(255, 71, 71);
      }
    }

    .leave-waitlist {
      width: 128px;
      margin-left: 236px;
    }

    .sign-out {
      font-size: 14px;
      font-weight: 600;
    }
  }

  @media (max-width: 768px) {
    .loading {
      margin-left: 0px;
    }

    .box {
      width: 90vw; // Reduced to fit smaller screens

      .license {
        display: none;
      }

      .user {
        width: 100%;

        p,
        img {
          float: right;
          display: block;
          text-align: center;
        }
      }

      .content {
        width: 90vw !important;
        margin: 0px auto !important;
        float: left;
      }

      .billing,
      .membership,
      .support {
        width: calc(90vw); // Reduced width
        // margin-left: 5%; // Centered
        // margin-right: 5%; // Centered
      }

      .payment-details {
        margin-left: 0px; // Adjusted width
        width: calc(100% - 54px);
      }

      .renewal,
      .staffmember,
      .license {
        float: none; // Avoid floating for stacking
        margin-left: 0px;
        width: calc(90vw - 80px); // Adjusted width
        // margin: 10px auto; // Vertical spacing and centered
      }

      input,
      select {
        // width: 80%; // Adjusted width
        // width: calc(90vw - 40px); // Adjusted width
        // margin-left: 5%; // Centered
      }

      .divider {
        width: 90%; // Adjusted width
        margin-left: 5%; // Centered
      }

      .join {
        margin-right: 0;
        margin-left: calc(50% - 50px); // Centered by reducing half of its width
      }

      .leave-waitlist,
      .sign-out {
        width: 85%; // Adjusted width
        margin-left: 5%; // Centered
        text-align: center; // Text centered
      }
    }
  }
}
