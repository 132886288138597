html {
  background-color: rgb(40, 40, 40, 0.1);
}

.form-input {
  width: 210px !important;
  float: left;
}

.form-input:invalid {
  border: 1px solid rgba(201, 0, 0, 0.473);
}

select {
  float: left;
}

.centered {
  margin-left: 100px;
}

.indented {
  margin-left: 10px;
}

.waitlist-management-form {
  margin: 0 auto;
}

.half_input {
  width: 20px !important;
  //   float: left;
  // margin-right: 10px;
}

.error-message {
  margin-left: 100px;
  margin-right: 70px;
  margin-top: 12px;
  margin-bottom: 4px;
  background-color: rgb(240, 128, 128, 0.3);
  border: 1px solid rgba(201, 0, 0, 0.473);
  border-radius: 6px;
  min-height: 24px;
  line-height: 24px;
  padding: 8px;
  font-size: 14px;
  font-weight: 400;
  color: rgb(40, 0, 0, 0.9);

  img {
    width: 20px;
    margin-top: 2px;
    margin-left: 6px;
    margin-right: 12px;
    float: left;
  }
}

@media (max-width: 768px) {
  .form-input,
  .select {
    width: calc(90%) !important;
    margin-left: 5% !important;
    margin: 0 auto;
  }

  .card {
    width: calc(100vw - 76px) !important;
    margin-left: 18px !important;
  }

  .tac {
    margin-left: 0px !important;
  }

  .indented {
    margin-left: 0px;
  }

  .centered {
    margin-left: 0px;
  }

  input {
    // width: 34px !import;
    // margin-right: 36px;
  }

  .card {
    margin-left: 0px;
  }
}
