.loginButton {
  margin: 0 auto;
  width: 180px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  border: 2px solid rgba(5, 0, 80, 0.2);
  border-radius: 4px;
  background-color: rgba(93, 102, 241);
  color: white;
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
