.navigation-bar {
  display: flex;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid rgb(40, 40, 40, 0.1);
  padding: 0 20px;
}

.logo img {
  height: 48px; /* Adjust the height as needed */
  margin-right: 20px;
}

.links {
  display: flex;
  gap: 20px;
}

.nav-link {
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  color: black;
  position: relative;
  padding: 10px 15px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.nav-link.active {
  background-color: rgb(80, 80, 80, 0.1);
}
