.users {
  // margin-left: 48px;
  // margin-right: 48px;
  margin: auto;
  max-width: 1600px;
  padding: 0 48px;

  // hr, h2, h3 {
  //     float: left;
  // }

  .search-bar {
    float: right;
    padding: 10px;
    border-radius: 4px;
    border: transparent;
    margin-top: 18px;
    width: 240px;
  }

  .invite-section,
  .overdue-section {
    float: left;
    width: 100%;

    .success-text {
      color: green;
    }

    .error-text {
      color: darkred;
    }

    input {
      float: left;
      padding: 12px;
      width: 256px;
      border-radius: 4px;
      border: transparent;
      margin-right: 12px;
    }

    button {
      width: 128px;
      background-color: royalblue;
      color: white;
      border-radius: 4px;
      border: transparent;
      height: 39px;
    }
  }

  .divider {
    border: 0.5px solid rgb(40, 40, 40, 0.5);
    // margin-top: 24px;
    float: left;
    opacity: 0.4;
  }

  .user-table {
    border-collapse: collapse;
    width: 100%;
    // max-width: 1200px;
    // padding: 48px;
    margin: auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    font-size: 14px;

    th,
    td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;
      height: 24px;
    }

    th {
      background-color: #f2f2f2;
      border-bottom: 2px solid #ccc;
    }

    tr {
      transition: background-color 0.2s ease-in-out;

      &:nth-child(even) {
        background-color: #f2f2f2;
      }

      &:hover {
        background-color: #e0e0e0;
      }
    }

    a {
      text-decoration: none;
      padding: 4px;
      border: 1px solid blue;
      border-radius: 4px;
      margin-right: 12px;
      // margin-top: 4px;
      font-size: 12px;
      padding-left: 12px;
      color: black;
      padding-right: 12px;
    }

    button {
      text-decoration: none;
      padding: 4px;
      border: 1px solid blue;
      border-radius: 4px;
      margin-right: 12px;
      // margin-top: 4px;
      font-size: 12px;
      padding-left: 12px;
      color: black;
      padding-right: 12px;
      float: left;
    }

    a:active {
      background-color: blue;
      color: white;
    }

    a:visited {
      color: black;
      text-decoration: none;
    }

    a:visited:active {
      color: white;
    }

    .green {
      background-color: rgb(144, 255, 144);
      color: darkgreen;
      border: 1px solid darkgreen;
    }

    .red {
      background-color: rgb(255, 144, 144);
      color: darkred;
      border: 1px solid darkred;
    }
  }
}
