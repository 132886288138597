.authentication-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.rounded-rectangle {
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 6px;
  text-align: center;
  background-color: #fff; /* Added white background */
}

input {
  width: calc(100% - 20px); /* Corrected input width to consider padding */
  padding: 10px;
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

button {
  padding: 8px 24px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
